import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "CardList",
    component: () => import("../views/CardList.vue"),
  },
  {
    path: "/add/",
    name: "AddCard",
    component: () => import("../views/AddCard.vue"),
  },
  {
    path: "/detail/:cardId",
    name: "CardDetail",
    component: () => import("../views/CardDetail.vue"),
  },
  {
    path: "/edit/:cardId",
    name: "EditCard",
    component: () => import("../views/EditCard.vue"),
  },
  {
    path: "/graph",
    name: "Graph",
    component: () => import("../views/Graph.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
